import * as React from "react"

export default function GatsbyWordpressLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.333 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12ZM5.647 18.686c-1.8-1.8-2.743-4.2-2.743-6.515l9.343 9.258c-2.4-.086-4.8-.943-6.6-2.743Zm8.743 2.485L3.162 9.943c.942-4.2 4.714-7.372 9.171-7.372 3.171 0 5.914 1.543 7.629 3.858l-1.286 1.114c-1.457-1.972-3.772-3.257-6.343-3.257-3.343 0-6.171 2.143-7.286 5.143l9.857 9.857c2.486-.857 4.372-3 4.972-5.572h-4.114V12h6c0 4.457-3.172 8.229-7.372 9.171Z"
        fill="currentColor"
      />
    </svg>
  )
}
