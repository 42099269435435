import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Section, Text, SuperHeading, Box } from "./ui"
import * as styles from "./about-hero.css"

export default function AboutHero(props) {
  const { heading, text, alt, image } = props

  return (
    <Section className={styles.aboutHeroWrapper}>
      <Box background="muted" radius="large">
        <Box center paddingY={5}>
          <Container>
            <SuperHeading className={styles.aboutHeroHeader}>
              {heading}
            </SuperHeading>
            {text && <Text className={styles.aboutHeroText}>{text}</Text>}
            <div className={styles.aboutHeroImage}>
              {image && (
                <GatsbyImage
                  alt={alt}
                  image={getImage(image.childImageSharp.gatsbyImageData)}
                  className={styles.aboutHeroImage}
                />
              )}
            </div>
          </Container>
        </Box>
      </Box>
    </Section>
  )
}
