import * as React from "react"
import { Menu, X } from "react-feather"
import { animateScroll as scroll } from "react-scroll"
import {
  Container,
  Flex,
  FlexList,
  Space,
  NavLink,
  NavLinkScroll,
  LanguageLink,
  InteractiveIcon,
  Nudge,
  VisuallyHidden,
} from "./ui"
import {
  mobileNavOverlay,
  mobileNavLink,
  desktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
  mobileNavSVGColorWrapper,
} from "./header.css"
import BrandLogo from "./brand-logo"

export default function Header({ navigation, languages, changeLanguage }) {
  const [isOpen, setOpen] = React.useState(false)

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [isOpen])

  return (
    <header>
      <Container className={desktopHeaderNavWrapper}>
        <Space size={2} />
        <Flex variant="spaceBetween">
          <NavLink to="/" onClick={scrollToTop}>
            <VisuallyHidden>Home</VisuallyHidden>
            <BrandLogo />
          </NavLink>
          <nav>
            <FlexList gap={4}>
              {navigation.length > 0 &&
                navigation.map((navItem, index) => (
                  <li key={index}>
                    <NavLinkScroll
                      to={navItem.href}
                      text={navItem.text}
                      offset={-100}
                      duration={1000}
                    />
                  </li>
                ))}
            </FlexList>
          </nav>
          <nav>
            <FlexList gap={4}>
              {languages.length > 0 &&
                languages?.map((language, index) => {
                  return (
                    <li key={index}>
                      <LanguageLink
                        to="#"
                        onClick={(e) => {
                          e.preventDefault()
                          changeLanguage(language)
                        }}
                      >
                        {language}
                      </LanguageLink>
                    </li>
                  )
                })}
            </FlexList>
          </nav>
        </Flex>
      </Container>
      <Container className={mobileHeaderNavWrapper[isOpen ? "open" : "closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
          <span
            className={
              mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
            }
          >
            <NavLink to="/">
              <VisuallyHidden>Home</VisuallyHidden>
              <BrandLogo />
            </NavLink>
          </span>
          <Flex>
            <Space />
            <Nudge right={3}>
              <InteractiveIcon
                title="Toggle menu"
                onClick={() => setOpen(!isOpen)}
                className={
                  mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
                }
              >
                {isOpen ? <X /> : <Menu />}
              </InteractiveIcon>
            </Nudge>
          </Flex>
        </Flex>
      </Container>
      {isOpen && (
        <div className={mobileNavOverlay}>
          <nav>
            <FlexList responsive variant="stretch">
              {navigation.length > 0 &&
                navigation?.map((navItem, index) => (
                  <li key={index}>
                    <NavLink to={navItem.href} className={mobileNavLink}>
                      {navItem.text}
                    </NavLink>
                  </li>
                ))}
            </FlexList>

            <br />
            <br />

            <FlexList responsive variant="stretch">
              <li>
                <NavLink className={mobileNavLink}>Languages:</NavLink>
              </li>

              {languages.length > 0 &&
                languages?.map((language, index) => {
                  return (
                    <li key={index}>
                      <LanguageLink
                        to="#"
                        className={mobileNavLink}
                        onClick={(e) => {
                          e.preventDefault()
                          changeLanguage(language)
                        }}
                      >
                        {language}
                      </LanguageLink>
                    </li>
                  )
                })}
            </FlexList>
          </nav>

          <nav>
            <FlexList responsive variant="stretch"></FlexList>
          </nav>
        </div>
      )}
    </header>
  )
}
