import * as React from "react"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css"
import "@fontsource/montserrat/800.css"

const Layout = (props) => {
  const { t } = useTranslation()
  const { languages, changeLanguage } = useI18next()

  const layoutData = {
    navigation: [...t("navigation", { returnObjects: true })],
    languages,
    changeLanguage,
  }

  return (
    <>
      <Head {...props} />
      <Header {...layoutData} />
      {props.children}
      <Footer {...layoutData} />
    </>
  )
}

export default Layout
