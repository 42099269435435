import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

// import Fallback from "../components/fallback"
import Layout from "../components/layout"
// import Hero from "../components/hero"
import HeroImageBackground from "../components/hero-image-background"
import FeatureList from "../components/feature-list"
// import LogoList from "../components/logo-list"
import BenefitList from "../components/benefit-list"
// import TestimonialList from "../components/testimonial-list"
// import StatList from "../components/stat-list"
// import Cta from "../components/cta"
// import ProductList from "../components/product-list"
import AboutHero from "../components/about-hero"
import Contact from "../components/contact"

import Config from "./../images/svg/icons/config.svg"
// import ConfigWhite from "./../images/svg/icons/config-white.svg"
import Diamont from "./../images/svg/icons/diamont.svg"
import Stats from "./../images/svg/icons/stats.svg"
// import { list } from "../components/ui.css"
// import getTranslation from "../utils/get-translation"
import parseList from "../utils/parse-list"

const IndexPage = ({ data }) => {
  const { t } = useTranslation()

  const {
    // clients,
    heroImage,
    serviceWepAppImage,
    serviceBackendImage,
    serviceAgileImage,
    serviceArchitectureImage,
    serviceUxImage,
    // productImages,
    jobImage,
  } = data || []

  const getTranslation = (string) => t(string, { returnObjects: true })

  // Hero data
  const heroData = {
    ...getTranslation("hero"),
    image: heroImage,
  }

  // Services data
  const servicesImages = [
    serviceWepAppImage,
    serviceBackendImage,
    serviceAgileImage,
    serviceArchitectureImage,
    serviceUxImage,
  ]

  const services = getTranslation("services")

  const servicesData = {
    ...services,
    list: parseList(services, servicesImages),
  }

  // Logo List
  // const logoData = {
  //   ...getTranslation("clients"),
  //   logos: clients,
  // }

  // Benefit List
  const benefitsListImages = [<Config />, <Diamont />, <Stats />]
  const benefits = getTranslation("benefits")

  const benefitsData = {
    ...benefits,
    list: parseList(benefits, benefitsListImages),
  }

  // About hero
  const aboutHeroData = {
    ...getTranslation("aboutHero"),
    image: jobImage,
  }

  // Contact
  const contactData = {
    ...getTranslation("contact"),
    // image: jobImage,
  }

  return (
    <Layout>
      <section id="home">
        <HeroImageBackground {...heroData} />
      </section>
      <section id="services">
        <FeatureList {...servicesData} />
      </section>
      {/* <section id="clients">
        <LogoList {...logoData} />
      </section> */}
      <section id="about">
        <BenefitList {...benefitsData} />
      </section>
      <section id="contact">
        <Contact {...contactData} />
      </section>
      <section id="job">
        <AboutHero {...aboutHeroData} />
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    clients: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "clients" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 128)
          }
        }
      }
    }
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    serviceWepAppImage: file(relativePath: { eq: "feature/1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    serviceBackendImage: file(relativePath: { eq: "feature/2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    serviceAgileImage: file(relativePath: { eq: "feature/3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    serviceArchitectureImage: file(relativePath: { eq: "feature/4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    serviceUxImage: file(relativePath: { eq: "feature/5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    productImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "product" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 80, height: 80)
          }
        }
      }
    }
    jobImage: file(relativePath: { eq: "job.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, height: 650)
      }
    }
  }
`
