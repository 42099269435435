import * as React from "react"
import { Container, Box, Kicker, Heading, Text } from "./ui"
import Feature from "./feature"

export default function FeatureList(props) {
  const { top, list } = props
  const { small, h1, paragraph } = top

  return (
    <Container width="fullbleed">
      <Box background="muted" radius="large">
        <Box center paddingY={5}>
          <Heading>
            {small && <Kicker>{small}</Kicker>}
            {h1}
          </Heading>
          <Text>{paragraph}</Text>
        </Box>
        {list.map((service, index) => (
          <Feature key={index} {...service} flip={Boolean(index % 2)} />
        ))}
      </Box>
    </Container>
  )
}
