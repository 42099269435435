import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Section, Flex, Box, Subhead, Kicker, Text } from "./ui"

export default function Feature(props) {
  const { small, h1, content, image: img } = props

  const image = getImage(img)

  return (
    <Section padding={4} background="muted">
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="half" order={props.flip ? 1 : null}>
            <GatsbyImage image={image} alt="" />
          </Box>
          <Box width="half">
            <Subhead>
              {small && <Kicker>{small}</Kicker>}
              {h1}
            </Subhead>
            {content.map((paragraph, index) => (
              <Text variant="lead" key={index}>
                {paragraph}
              </Text>
            ))}
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}
