import React from "react"
import { Subhead, Flex, Text } from "./ui"
import { toastWrapper, btnClose, hideToat, success, alert } from "./toast.css"

const Toast = ({
  handleClose,
  conf: { type = "success", visible = false, heading, body },
}) => {
  return (
    <div
      className={`${toastWrapper} ${type === "success" ? success : alert}  ${
        !visible ? hideToat : null
      }`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <Flex>
        <div>
          <Subhead as="h4">{heading}</Subhead>
          <Text as="p">{body}</Text>
        </div>
        <button
          type="button"
          className={btnClose}
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={handleClose}
        />
      </Flex>
    </div>
  )
}

export default Toast
