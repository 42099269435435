import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import axios from "axios"
import { Box, Container, Heading, Section } from "./ui"
import {
  contactWrapper,
  controlsWrapper,
  label,
  input,
  invalid,
  fullWidthWrapper,
  btn,
} from "./contact.css"

// Components
import Toast from "./toast"

export const Contact = (props) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    subject,
    message,
    submitting,
    submit,
    validation,
  } = props || []

  const [conf, setConf] = useState({ visible: false })

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  }

  const schema = Yup.object({
    firstName: Yup.string().required(validation.firstNameRequired),
    lastName: Yup.string().required(validation.lastNameRequired),
    email: Yup.string()
      .required(validation.emailRequired)
      .email(validation.emailInvalid),
    phone: Yup.string().required(validation.phoneRequired),
    subject: Yup.string().required(validation.subjectRequired),
    message: Yup.string().required(validation.messageRequired),
  })

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    const { firstName, lastName, email, phone, subject, message } = values || {}

    const formValues = {
      firstName,
      lastName,
      email,
      phone,
      subject,
      message,
    }

    axios({
      method: "post",
      url: `${process.env.GATSBY_AWS_API_GATEWAY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(formValues),
    })
      .then((response) => {
        if (response.status === 200) {
          resetForm()
          setConf({
            type: "success",
            error: false,
            heading: validation.successHeader,
            body: validation.successBody,
            visible: true,
          })
          setTimeout(() => handleClose(), 5000)
        }
      })
      .catch(function (error) {
        setSubmitting(false)
        if (error) {
          setConf({
            type: "alert",
            error: true,
            heading: validation.errorHeader,
            body: validation.errorBody,
            visible: true,
          })
          setTimeout(() => handleClose(), 5000)
        }
      })
  }

  const handleClose = () => {
    setConf({ ...conf, visible: false })
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isValid,
        handleBlur,
        isSubmitting,
      }) => (
        <Section className={contactWrapper}>
          <Toast handleClose={handleClose} conf={conf} />
          <Form
            noValidate
            onSubmit={handleSubmit}
            action={`${process.env.GATSBY_AWS_API_GATEWAY}`}
            method="POST"
          >
            <Box paddingY={5} background="primary">
              <Container>
                <Heading as="h2">Escribanos</Heading>
                <Box className={controlsWrapper}>
                  <div>
                    <label className={label}>{firstName}</label>
                    <Field
                      type="text"
                      className={input}
                      placeholder={firstName}
                      name="firstName"
                      id="firstName-input"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName ? (
                      <span className={invalid}>{errors.firstName}</span>
                    ) : null}
                  </div>
                  <div>
                    <label className={label} htmlFor="lastName-input">
                      {lastName}
                    </label>
                    <Field
                      type="text"
                      className={input}
                      placeholder={lastName}
                      name="lastName"
                      id="lastName-input"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName ? (
                      <span className={invalid}>{errors.lastName}</span>
                    ) : null}
                  </div>
                  <div>
                    <label className={label} htmlFor="email-input">
                      {email}
                    </label>
                    <Field
                      type="email"
                      className={input}
                      placeholder={email}
                      name="email"
                      id="email-input"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <span className={invalid}>{errors.email}</span>
                    ) : null}
                  </div>
                  <div>
                    <label className={label} htmlFor="phone-input">
                      {phone}
                    </label>
                    <Field
                      type="text"
                      className={input}
                      placeholder={phone}
                      name="phone"
                      id="phone-input"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && touched.phone ? (
                      <span className={invalid}>{errors.phone}</span>
                    ) : null}
                  </div>
                  <div>
                    <label className={label} htmlFor="subject-input">
                      {subject}
                    </label>
                    <Field
                      type="text"
                      className={input}
                      placeholder={subject}
                      name="subject"
                      id="subject-input"
                      value={values.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && touched.subject ? (
                      <span className={invalid}>{errors.subject}</span>
                    ) : null}
                  </div>
                  <div className={fullWidthWrapper}>
                    <label className={label} htmlFor="message-input">
                      {message}
                    </label>
                    <Field
                      className={input}
                      name="message"
                      id="message-input"
                      rows={3}
                      value={values.message}
                      onChange={handleChange}
                      component="textarea"
                    />
                    {errors.message && touched.message ? (
                      <span className={invalid}>{errors.message}</span>
                    ) : null}
                  </div>
                  <div className={fullWidthWrapper}>
                    <button
                      className={btn}
                      disabled={!isValid || isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? submitting : submit}
                    </button>
                  </div>
                </Box>
              </Container>
            </Box>
          </Form>
        </Section>
      )}
    </Formik>
  )
}

export default Contact
