import * as React from "react"
import {
  Container,
  Section,
  FlexList,
  Box,
  // Icon,
  Heading,
  Text,
  Space,
} from "./ui"

function Benefit(props) {
  // const { image, heading, text } = props || null
  const { image, heading } = props || null

  return (
    <Box as="li" width="third" paddingY={0} center>
      {image}
      <Space size={2} />
      <Heading variant="subheadSmall">{heading}</Heading>
      {/* <Text>{text}</Text> */}
    </Box>
  )
}

export default function BenefitList(props) {
  const { heading, text, list } = props || null

  return (
    <Section style={{ paddingTop: 0 }}>
      <Container>
        <Box center>
          {heading && <Heading>{heading}</Heading>}
          {text &&
            text.map((item, index) => (
              <Text variant="lead" key={index}>
                {item}
              </Text>
            ))}
        </Box>
        <FlexList gutter={3} variant="start" responsive wrap>
          {list.map((benefit, index) => (
            <Benefit key={index} {...benefit} />
          ))}
        </FlexList>
      </Container>
    </Section>
  )
}
