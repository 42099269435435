import * as React from "react"
import { Box, Flex, Heading, Kicker, Text } from "./ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HeroImageBackground(props) {
  const { small, h1, paragraph, image: img } = props || null
  const image = getImage(img)

  return (
    <Box paddingY={0} background="primary">
      <Flex gap={0} variant="responsive">
        <Box width="half">{image && <GatsbyImage image={image} alt="" />}</Box>
        <Box width="half" padding={4}>
          <Heading as="h1">
            <Kicker>{small}</Kicker>
            {h1}
          </Heading>
          <Text as="p">{paragraph}</Text>
        </Box>
      </Flex>
    </Box>
  )
}
